@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --sidebar-flex: 2;
    --condiv-flex: 6;
    --fontsize-p: 1.2rem;
    --fontsize-nav: 1.6rem;
    --typing-font: 3rem;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Raleway', serif;
}

.App {
    display: flex;
    width: 100%;
    height: 100vh;
}

.container {
    padding: 5rem;
    background-color: #f4f5f9;
    flex: 6;
    flex: var(--condiv-flex);
}

nav {
    padding: 60px 30px 0 30px;
    flex: 2;
    flex: var(--sidebar-flex);
    background: #373B44;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4286f4, #373B44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

nav ul {
    font-size: 1.6rem;
    font-size: var(--fontsize-nav);
    text-align: center;
    list-style-type: none;
    text-decoration: none;
    color: white !important;
}

nav ul li {
    margin: 40px;
}

.active {
    font-weight: bolder;
}

a {
    text-decoration: none;
    color: white;
}

.home {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.picture {
    border-radius: 50%;
    width: 200px;
    margin: 30px;
}

.typing-effect {
    font-size: 3rem;
    font-size: var(--typing-font);
    margin-bottom: 30px;
    text-align: center;
}

.social {
    position: absolute;
    margin-top: 20px;
    display: flex;
    bottom: 60px;
}

.social i {
    padding-right: 30px;
    font-size: 25px;
    color: #9a9b9c;
}

.social i:hover {
    color: #ffffff;
}

.social svg {
    padding-right: 30px;
    font-size: 25px;
    color: #9a9b9c;
}

.social svg:hover {
    color: #000000;
}

.subtopic {
    margin: 10px 10px 30px 10px !important;
}

p {
    font-size: 1.2rem;
    font-size: var(--fontsize-p);
}

h1, h2, h3, h4, h5, hr, p {
    margin: 10px;
}

hr {
    width: 30%;
}

.card-item {
    border: 0 solid #9a9b9c;
    border-radius: 10px;
    display: flex;
    margin-bottom: 30px;
}

.card-item:hover {
    color: #1b1d20;
    background-color: #ffffff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.skills ul li {
    list-style-type: none;
    margin: 30px;
}

.experience-container {
    overflow: auto;
}

.normal-font-weight {
    font-weight: normal;
}

.font-weight-bold {
    font-weight: bold;
}

.project-list {
    padding-left: 2rem;
}

